<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useGeneralStore } from "../../stores/useGeneralStore";
import ApiService from "../../common/service.api";
import userTemplate from "./layout/userTemplate.vue";
import Button from "./components/Button.vue";

import MasterLayout from "../../components/MasterLayout.vue";

const startedQuiz = ref(false);
const generalStore = useGeneralStore();
const loading = ref({ gen: false });
const router = useRouter();
const route = useRoute();
const currentRisposta = ref(null);
const survey = computed(() => generalStore.currentSurvey);
const selectedRating = ref(null); // Valor de la evaluación seleccionada
const hoverRating = ref(0); // Valor de la evaluación en hover
const showFinalCard = ref(false);
const HIGHLIGHT_DELAY = 1000;

function startQuiz() {
  startedQuiz.value = true;
  console.log("erogazione", survey.value);
  if (survey.value.risposte.length > 0) {
    currentRisposta.value = survey.value.risposte[0];
  }
}

async function saveAndGoToNext() {
  if (selectedRating.value !== null) {
    // Guardar la respuesta actual
    currentRisposta.value.valore = selectedRating.value;

    const currentIndex = survey.value.risposte.indexOf(currentRisposta.value);
    console.log("currentIndex", currentIndex);
    console.log("survey.value.risposte.length", survey.value.risposte.length);

    // Si hay más preguntas, pasa a la siguiente
    if (currentIndex >= 0 && currentIndex < survey.value.risposte.length - 1) {
      currentRisposta.value = survey.value.risposte[currentIndex + 1];
      selectedRating.value = null; // Resetea el rating para la próxima pregunta
    } else {
      // Si es la última pregunta, marca el cuestionario como completado
      await completeSurvey();
    }
  }
}

async function completeSurvey() {
  // Redirigir a la página principal
  router.push("/pk-end-survey/" + route.params.idSurvey);
}

function handleRatingClick(rating) {
  if (currentRisposta.value.data_risposta || isSurveyCompleteOrDeleted())
    return;
  selectedRating.value = rating; // Actualiza el rating seleccionado
}

function handleHover(rating) {
  hoverRating.value = rating; // Actualiza el valor de hover
}

function handleMouseLeave() {
  hoverRating.value = 0; // Resetea el hover cuando el usuario deja de pasar sobre las estrellas
}

function isSurveyCompleteOrDeleted() {
  return survey.value.completato || survey.value.eliminato;
}

onMounted(() => {
  startQuiz();
});
</script>

<template>
  <!-- <userTemplate> -->

  <master-layout :showBack="false" smallTitle="il tuo" bigTitle="Questionario">
    <div
      class="flex flex-column justify-content-between align-items-stretch"
      style="height: calc(100vh - 150px)"
    >
      <div class="page-container">
        <div class="main-image">
          <img src="../../../public/assets/img/yellowStar.png" />
        </div>
        <h4 class="text-16 mt-4 mb-2">
          {{ startedQuiz ? currentRisposta?.domanda?.t_domanda["it-IT"] : "" }}
        </h4>

        <!-- Sección de evaluación con estrellas -->
        <div class="rating-container">
          <div
            v-for="x in 5"
            :key="x"
            @click="handleRatingClick(x)"
            @mouseover="handleHover(x)"
            @mouseleave="handleMouseLeave"
            style="cursor: pointer"
          >
            <!-- Mostrar la estrella llena o vacía -->
            <img
              :src="
                x <= hoverRating || x <= selectedRating
                  ? '/assets/img/fullStar.png'
                  : '/assets/img/emptyStar.png'
              "
              style="margin-left: 0.3rem; margin-right: 0.3rem"
            />
          </div>
        </div>
      </div>

      <div class="footer-container">
        <div class="button-container">
          <button class="w-full button-primary p-d" @click="saveAndGoToNext">
            Next
            <span class="arrow">→</span>
          </button>
        </div>
      </div>
    </div>

    <!-- <template #footer> -->
    <!-- Botón para continuar -->

    <!-- </template> -->
  </master-layout>

  <!-- </userTemplate> -->
</template>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 1rem 2rem;
  margin-top: 32px;
  background-color: white;
  border-radius: 10px;
}

.rating-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
}

.rating-container img {
  transition: transform 0.2s ease-in-out;
}

.rating-container img:hover {
  transform: scale(1.2);
}

/* Botón "Next" */
.button-container {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.next-button {
  width: 100%;
}

.arrow {
  margin-left: 0.5rem;
  font-size: 1.2rem;
}

.card {
  border-radius: 10px;
  background-color: var(--ion-color-secondary);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
}
.footer-container {
  width: 100%;
  padding: 20px;
  z-index: 2;
}
</style>
